







































import { MutationPayload } from 'vuex';
import { IEventV2 } from '@/models/event';
import router from '@/router';
import store from '@/store';
import { IEventCard } from '@/partials';
import EventCard from '@/partials/EventCard.vue';
import Input from '@/components/form/input.vue';
import { IEventsParams, IStateEvent } from '@/store/modules/events';
import { eventFilters, eventFiltersModel } from './index';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import useAuth from '@/composition/auth';

// @ is an alias to /src

export default defineComponent({
  components: { EventCard, Input },

  data() {
    return {
      events: [] as IEventV2[] | IStateEvent[],
      filters: eventFilters,
      model: eventFiltersModel,
      searchTimeout: 0,
    };
  },
  setup() {
    const { relatedEvents, userAdmin, translations, userRolesSet } = useContext();
    const { userLoggedIn } = useAuth();

    const eventsState = computed(() => store.getters.getEventsState);

    return {
      relatedEvents,
      userAdmin,
      translations,
      userRolesSet,
      userLoggedIn,
      eventsState,
    };
  },
  created() {
    store.commit('setContextEvent', {});
    this.filters.search.onInput = this.searchEvents;
    if (this.userRolesSet) {
      this.checkRoles();
    } else {
      store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setRoles') {
          this.checkRoles();
        }
      });
    }
  },
  methods: {
    checkRoles() {
      if (this.userAdmin) {
        store.dispatch('fetchEvents', {
          page: 1,
          page_size: 100,
          reset: true,
        });
        store.subscribe((mutation: MutationPayload) => {
          if (mutation.type === 'setEvents') {
            this.events = this.eventsState.list;
            if (this.events.length === 0) {
              router.push('/');
            }
          }
        });
      } else if (this.userLoggedIn) {
        this.events = this.relatedEvents;
        if (this.relatedEvents.length === 1) {
          router.push(`/event/${this.relatedEvents[0].id}`);
        }
      } else {
        router.push('/');
      }
    },

    cardConfig(event: IEventV2) {
      const config: IEventCard = {
        id: event.id,
        name: event.name,
        start_date: event.start_date,
        end_date: event.end_date,
        image: event.image ? event.image : '',
        route: `/event/${event.id}/roles`,
      };
      return config;
    },

    nextPage() {
      if (this.eventsState.page) {
        const params: IEventsParams = {
          page: this.eventsState.page + 1,
          page_size: 100,
        };
        store.dispatch('fetchEvents', params);
      }
    },

    searchEvents(value: string | number) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        const params: IEventsParams = {
          search: value as string,
          page: 1,
          page_size: 100,
          reset: true,
        };
        store.dispatch('fetchEvents', params);
      }, 1000);
    },
  },
});
