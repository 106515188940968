
























































import { defineComponent, PropType } from '@vue/composition-api';
import useContext from '@/composition/context';
import { IEventCard } from '.';
import useDates from '@/composition/dates';

// @ is an alias to /src
export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IEventCard>,
      required: true,
    },
  },
  setup() {
    const { relatedEvents, relatedExhibitors, userExhibits, userVisits, userManages, userAdmin } =
      useContext();

    const { getDate, getTime } = useDates();

    return {
      getDate,
      getTime,
      relatedEvents,
      relatedExhibitors,
      userExhibits,
      userVisits,
      userManages,
      userAdmin,
    };
  },
});
