import { IInput } from '@/components/form/index';
import { ILoginInputs } from '@/partials';
import { ITranslations } from '@/translations/index';

export interface IEventFilters {
  search: IInput;
}

export const form = {
  email: '',
  password: '',
};

export const eventFilters: IEventFilters = {
  search: {
    id: 'event-search',
    name: 'event_search',
    placeholder: 'Search',
    value: '',
    type: 'text',
  },
};

export const eventFiltersModel = {
  search: '',
};

export const inputs = (translations: ITranslations) => {
  const email = {
    id: 'user-email',
    name: 'email',
    label: translations.auth.login.email,
    type: 'text',
    icon: 'mail_outline',
    placeholder: translations.auth.login.email_placeholder,
    value: '',
  };

  const password = {
    id: 'user-password',
    name: 'password',
    label: translations.auth.login.password,
    type: 'password',
    icon: 'lock_open',
    placeholder: translations.auth.login.password_placeholder,
    value: '',
  };
  return {
    email, password,
  } as ILoginInputs;
};
